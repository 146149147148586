.Result_inter{
    background: linear-gradient(rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)),
    url('../assets/images/InternationalTrip2.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 45vh;
    overflow-x: hidden;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: -50px;
    margin-bottom: 50px;
}

.Result_inter h1{
    font-size: 2.5rem;
    color: #fff;
    font-weight: 700;
    justify-content: center;
    text-align: center;
}