.tour__content img {
    width: 100%;
    border-radius: 0.5rem;
    margin-bottom: 2.5rem;
}

.tour__info, .tour__details{
    border-radius: 0.5rem;
    border: 1px solid rgb(229, 231, 235);
    padding: 1rem;
}

.tour__info h2{
    font-size: 1.5rem;
}

.tour__info span {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-style: 1rem;
    color: var(--text-color);
}

.tour__info span i{
    color: var(--heading-color);
    font-size: 1.1rem;
}

.tour__extra-details{
    display: flex;
    align-items: center;
    column-gap: 2.7rem;
    margin-top: 1rem;
    margin-bottom: 2.5rem;

}

.tour__info h5{
    margin-bottom: 1px;
    font-size: 1.3em;
}

.tour__info p{
    line-height: 30px;
    font-size: 1rem;
    color: var(--text-color);
}

.tour__details .sc-Outer {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    margin-top: 15px;
  }
  
  .tour__details .sc-Inner {
    font-size: 18px;
    font-weight: 600;
    padding: 5px 12px;
    background-color: #0D5C75;
    color: #fff;
    border-radius: 10px;
    
  }
  
  .tour__details .sc-Inner-title {
    font-size: 16px;
    font-weight: 600;
    padding: 5px 12px;
    background-color: #199FB1;
    color: #fff;
    border-radius: 10px;
    width: 500px;
  }
  .tour__details .sc-Inner-info{
    font-size: 18px;
    font-weight: 600;
    padding: 5px 12px;
    padding-left: 30px;
    background-color: #A5D1E1;
    color: #000000;
    border-radius: 10px;
    margin-top: 10px; 
    list-style: auto; 
  }
  
  .tour__details .Date-heading{
    font-size: 18px;
    font-weight: 600;
    margin: 20px auto;
    padding: 5px 12px;
    background-color: #0D5C75;
    color: #fff;
    border-radius: 10px;
    width: fit-content;
  }
  .tour__details .Month-heading {
    justify-content: center; /* Horizontally center the content */
    align-items: center; /* Vertically center the content */
    font-size: 18px;
    font-weight: 600;
    margin-top: 15px;
    padding: 2px 10px;
    background-color: #199FB1;
    color: #fff;
    border-radius:10px;
    width: 90px;
    cursor: pointer;
    height: 30px; /* Same as width to create a perfect circle */
  }
  .tour__details .ul{
    min-height: 3vw;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 48px;
    margin-bottom: 16px;
  }
  /* Apply the following styles to the <table> element */
table {
  width: 100%;
  text-align: left;
  margin-top: 20px;
  border: 1px solid #ddd;
}

/* Style the table header cells (<th>) */
th {
  background-color: #f2f2f2;
  padding: 12px 16px;
  font-weight: bold;
  border-bottom: 2px solid #ddd;
}

/* Style the table body rows (<tr>) */
tr:nth-child(even) {
  background-color: #f2f2f2;
}

/* Style the table body cells (<td>) */
td {
  padding: 10px 16px;
  border-bottom: 1px solid #ddd;
}

/* Apply hover effect on table rows */
tr:hover {
  background-color: #e0e0e0;
}

.tour__details .list {
    font-size: 20px;
  }



  .dates-and-costing {
    background-color: #f0f0f0;
    padding: 20px;
  }
  
  .Month-heading {
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
    color: #333;
  }
  
  .Month-heading.active {
    color: #000000; 
  }
  
  .list {
    font-size: 18px;
    font-weight: 600;
    padding: 5px 12px;
    padding-left: 30px;
    background-color: #A5D1E1;
    color: #000000;
    border-radius: 10px;
    margin-top: 10px !important; 
    list-style: auto; 
    list-style-type: none;
    margin: 0;
    padding-left: 5px;
    max-height: 0; 
    overflow: hidden;
    animation: fadeIn 1.5s ease forwards; 
  }
  
  @keyframes fadeIn {
    to {
      opacity: 1;
      max-height: 300px; 
    }
  }
  
  .list.active {
    display: block;
  }
  
  /* Mobile styles */
@media screen and (max-width: 767px) {
  .tour__details{
    padding: 0;
}
  .tour__extra-details {
    flex-direction: column;
    flex-wrap: wrap;
    row-gap: 1rem;
  }

  .tour__extra-details span {
    width: 100%;
    margin-bottom: 1rem;
    text-align: center;
    font-size: 0.9rem;
  }

  .tour__extra-details span i{
    font-size: 1rem;
}
.tour__details button{
  font-size: 70%;
}
}

/* iPad styles */
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .tour__extra-details {
    flex-wrap: wrap;
    gap: 1rem;
  }

  .tour__extra-details span {
    flex-basis: calc(50% - 1rem);
  }
}
