.tour__card a {
  text-decoration: none;
}
.tour__img img{
    width: 100%;
    border-radius: 5px 5px 0 0;
    height: 300px;
}

.card{
    border: none !important;
    
}

.tour__img{
    position: relative;
    
}

.tour__img span{
    position: absolute;
    bottom: 0;
    right: 0;
    width: max-content;
    height: max-content;
    z-index: 10;
    background: var(--primary-color);
    color: #fff;
    padding: 0.3rem 0.5rem;
    border-radius: 3px 0 0 0;
}

.card__top span{
    color: var(--secondary-color);
}

.tour__rating{
    font-size: 0.9rem;
    color: var(--text-color);
}
.tour__location{
    color: var(--heading-color);
    font-weight: 500;
    font-size: 0.9rem;
}

.tour__rating i, .tour__location{
    font-size: 1rem;
}



.tour__title a{
    text-decoration: none;
      color: var(--heading-color);
      transition: 0.3s;
  }
  
  .tour__title{
      margin-top: 1rem;
      font-size: 1.1rem;
      cursor: pointer;
  }
  
  .tour__title a:hover{
      color: var(--secondary-color);
  }
  
  .card__bottom h5{
      color: var(--secondary-color);
      font-size: 1.1rem;
      font-weight: 700;
  }
  .card__bottom h5 span{
      font-weight: 500;
      color: var(--text-color);
      font-size: 0.8rem;
  }
  
  .booking__btn{
      background:var(--primary-color) !important;
      cursor: pointer;
      padding: 0.2rem 0.5rem !important;
  }
  
  .booking__btn a{
      text-decoration: none;
      font-size: 0.9rem;
      color:#fff;
  }
  .booking__btn a:hover{
      color: #fff;
  }
  
  @media screen and (max-width: 576px) {
      .tour__title{
          font-size: 1rem;
      }
      .booking__btn a{
          font-size: 0.8rem;
      }
      .booking__btn{
          padding: 0.2rem 0.5rem !important;
      }
      .tour_location, .tour_rating{
          font-size: 0.8rem;
      }
    }