.header {
  
  width: 100%;
  height: 60px;
  line-height: 60px;
  background-color: #ffffff;
  z-index: 999;
  position: sticky;
  top: 0;
  left: 0;
  box-shadow: 3px 3px 8px -3px #ddd;
  opacity: 20px;

}

.logo img {
width: 90px;
margin-left: 50px;
}
.navigation {
  width: fit-content;
  height: 100%;
  margin-left: 25%;
}

.menu {
margin-bottom: 0;
margin-right: 0px;
}

.nav__item a, .upcoming {
text-decoration: none;
color: var(--heading-color);
font-weight: 300;
font-size: 1.1rem;
cursor: pointer;
}

.nav__item a:hover, .upcoming:hover {
color: var(--secondary-color);
}

.nav_item a.active_link{
color: var(--secondary-color);
font-weight: bold;
}

.nav__right{
margin-right: 50px;
}

.mobile__menu {
font-size: 1.3rem;
color: var(--heading-color);
display: none;
}

.sticky__header {
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 60px;
line-height: 60px;
z-index: 999;
box-shadow: 3px 3px 8px -3px #ddd;
}

/*making menu responsive*/
@media screen and (max-width: 1310px){
  .navigation{
    margin-left: 0;
  }
}


/* make it responsive for tabs and smaller screens*/

@media screen and (max-width: 1024px) {
.logo img {
  width: 80px;
}

.navigation {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.276);
  z-index: 999;
  display: none;
}

.menu {
  position: absolute;
  top: 0;
  right: 0;
  width: 200px;
  height: fit-content;
  background: white;
  flex-direction: column;
  line-height: 2rem;
  z-index: 9999;
  padding: 40px 0;
  border-radius: 40px 5px;
}

.nav__item{
  width: 100%;
  border-bottom: solid;
  border-color: var(--secondary-color);
}

.nav__item a, .upcoming {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
}

.show__menu {
  display: block;
}

.mobile__menu {
  display: block;
  margin-left: 20px;
}

}

@media screen and (max-width: 768px) {
  .logo img {
    margin-left: 10px;
    }

    .nav__right{
      margin-right: 10px;
      }
}