.Weekend_inter {
    background: linear-gradient(rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)),
    url('../assets/images/WeekendTrips2.jpg') no-repeat center center;
    background-size: cover;
    height: 45vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.Weekend_inter h1{
    font-size: 2.5rem;
    color: #fff;
    font-weight: 700;
}

.pagination span{
    width: 2rem;
    height: 2rem;
    padding: 5px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--secondary-color);
    font-size: 1.2rem;
    cursor: pointer;
    font-weight: 700;
}

.active__page{
    background: var(--secondary-color);
    color: #fff !important;
}

