.customSection{
    background: #A5D1E1;
    padding: 0;
    margin-top: 2rem;
}

.custom__content{
    padding: 50px 0;
}
.custom__content h2{
    font-size: 2.5rem;
    margin-bottom: 2rem;
    font-weight: 700;
}

  .custom__input input,
  .custom__btn {
    flex: 1;
    min-width: 0;
  }
  
  .custom__input{
    display:flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    background: #fff;
    padding: 0.5rem;
    margin-bottom: 2rem;
    border-radius: 5px;
    width: 100%;
}
.custom__input input:focus{
    outline:none;
}
.custom__input input{
    border: none;
    font-size: 1.1rem;
    color: var(--heading-color);
}

  
.custom__btn{
    padding: 0.5rem 1.5rem !important;
    background:var(--primary-color) !important;
    color: #fff !important;
    font-size: 1.1rem;
}

.custom__content p{
    color: var(--heading-color);
    font-size: 1.1rem;
}

/* CSS for screens up to 992px */
@media screen and (max-width: 992px) {
    .custom__content h2 {
        font-size: 2rem; /* Change font size for h2 at this breakpoint */
        width: fit-content;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
    }
    .custom__input {
      /* Add any specific styles for the input fields at this breakpoint */
      display: flex;
      flex-direction: column;
      width: fit-content;
      padding-top: 30px;
      align-items: center;
      justify-content: center;
      margin: 40px auto;
    }
    .custom__input input{
      margin-bottom: 10px;
      padding: 10px;
      border-bottom: 1px solid #ccc;
      border-radius: 5px;
      width:500px
    }
    .custom__input button {
      width: 80%;
    }
    .custom__content p {
      /* Add any specific styles for the paragraph element at this breakpoint */
      font-size: 18px;
      line-height: 1.6;
    }
  }
  
  /* CSS for screens up to 576px */
  @media screen and (max-width: 576px) {
    .custom__content h2 {
      font-size: 1.5rem; /* Change font size for h2 at this breakpoint */
    }
    .custom__content p {
      display: none; /* Hide the paragraph element at this breakpoint */
    }
    .custom__input {
      /* Add any specific styles for the input fields at this breakpoint */
      width: 100%;
    }
    .custom__input input {
        width: 90%;
    }
  }
  