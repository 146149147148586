.top_cover_about{
    background: linear-gradient(rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)),
    url('../assets/images/InternationalTrip.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 45vh;
    overflow-x: hidden;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: -50px;
    margin-bottom: 50px;
}
.sub_heading{
    font-weight: 700;
}
.about__us h2{
    font-weight: 700;
}
.about-img img{
    width: 100%;
    height: 800px;
    border-radius: 20px;
    border: 1px solid var(--secondary-color);
    object-fit: cover;
    margin-top: 40px;
}
.my_details{
    width: 100%;
    height: 400px !important;
    border-radius: 20px;
    margin-left: 55%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.card-image{
    width: 100%;
    height: 400px !important;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.nameOwn{
    font-size: 25px;
    font-weight: 500;
}



/* Existing styles */

/* Add media query for smaller screens */
@media screen and (max-width: 768px) {
    .team_div {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  
    .col-md-2 {
      flex: 0 0 50%; /* Display 2 cards in a row */
      max-width: 50%;
      margin-bottom: 20px;
    }
  
    .my_details {
      width: 100%;
      height: auto !important;
      max-height: none;
      border-radius: 20px;
      margin: auto;
    }
  
    .card-image {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
  }
  
  /* Additional styles for smaller screens (optional) */
  @media screen and (max-width: 576px) {
    .col-md-2 {
      flex: 0 0 100%; /* Display 1 card in a row */
      max-width: 100%;
    }
  
    .about-img img {
      height: auto;
    }

    .team_container{
      width: fit-content;
  }
    .team_div{
        width: fit-content;
    }
    .card-image{
      width: 80%;
    }
  }
  
