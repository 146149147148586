.booking{
    padding: 2rem;
    border-radius: .5rem;
    border: 1px solid rgb(229,231, 235);
    position: sticky;
    top: 80px;
}


.booking__top span{
    font-size: 1.1rem;
    font-weight: 600;
    color: var(--text-color);
}

.booking__top{
    padding-bottom: 2rem;
    border-bottom: 1px solid rgb(229, 231, 235);
}

.booking__form{
    padding-top: 2rem;
}

.booking__form h5{
    margin-bottom: 1rem;
}

.booking__form input{
    width: 100%;
    padding: 0.5rem;
    border-radius: .5rem;
    color: var(--heading-color);
    font-size: 1rem;
    border: none;
    border-bottom: 1px solid rgb(229, 231, 235);
    
}

.booking__form input:focus{
    outline: none;
}

.booking__form p{
    color: rgba(44, 44, 44, 0.664);
    font-size: small;
}

.booking__info-form{
    padding: 1rem;
    border: 1px solid rgb(229, 231, 235);
}

.total h5, .total span{
    color: var(--heading-color) !important;
    font-weight: 700 !important;
    font-size: 1.1rem !important;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.dayicon{
    font-size: 1.2rem;
    margin-left: 35px;
}


.booking__top i{
    margin-bottom: 7px;
    padding: 0;
    color: black !important;
}
.ri-time-line{
    margin-top: 2px;
}