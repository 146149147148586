.search__bar{
    padding: 0.5rem 1rem;
    border-radius:50px;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    width: 700px;
    margin-top: 50px;
    background-color: #fff;
}
.form_group span{
    font-size: 2rem;
    color:#0D5C75;

}
.form_group input{
    font-size: 0.9rem;
    color: var(--heading-color);
    font-weight: 500;
    width: 550px;
    border: none !important;
}
.form_group input:focus{
    margin-bottom: 0;
    outline: none;
}
.form_group h6{
    margin-bottom: 0;
}
.search__icon{
    font-size: 1.5rem;
    padding: 0.5rem;
    background:var(--primary-color);
    color: #fff;
    border-radius: 10px 5px 10px 5px;
    cursor: pointer;
}


@media screen and (max-width: 750px) {
    .search__bar{
        width: 550px;
    }

    .form_group input{
        width: 400px;
        
    }
  }



  @media screen and (max-width: 600px) {
    .search__bar{
        width: 350px;
    }

    .form_group input{
        width: 200px;
        
    }
  }

  @media screen and (max-width: 370px) {
    .search__bar{
        width: 100%;
        height: 70px;
    }

    .form_group input{
        width: 80%;
    }
    .form_group span{
        font-size: 1.5rem;
        color:#0D5C75;
    }
    .search__icon{
        font-size: 1rem;
    }
  }
